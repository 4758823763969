import { AddIcon } from "assets/media/icons/other_icons/AddIcon";
import { DeleteIconButton } from "components/ui/buttons/DeleteIconButton/DeleteIconButton";
import { StyledButton } from "components/ui/buttons/StyledButton/StyledButton";
import { useState } from "react";
import AlgoliaAll from "../../component/UI/AlgoliaAll";
import { INITIAL_TYPES } from "../../core/constants/constants";
import {
  addNestedCategory,
  handleHitSelect,
  handleNestHitSelect,
  removeNestedCategory,
} from "./util";

export const EditRecordForm = ({ categories, setCategories }: any) => {
  const [openModalIndex, setOpenModalIndex] = useState(null);

  const handleToggle = (index: any) => {
    if (openModalIndex === index) {
      setOpenModalIndex(null); // Close the modal if it's already open
    } else {
      setOpenModalIndex(index); // Open the new modal
    }
  };

  return (
    <div className="right_body border-r lg2:w-1/2 w-full xl:h-full scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full overflow-auto p-5">
      <div className="fieldset flex flex-wrap -mx-2.5">
        {categories.map(
          (item: any, index: any) =>
            !item?.is_deleted && (
              <div className="w-full mb-5 px-2.5 relative" key={index}>
                {/* DELETE   */}
                <div className="flex mb-2 gap-4">
                  <AlgoliaAll
                    inputValue={item?.name}
                    type={INITIAL_TYPES[item?.type] ?? item?.type}
                    value={item}
                    index={`menu-${index}`}
                    handleHitSelect={handleHitSelect(setCategories, index)}
                    width={"full"}
                  />
                  <DeleteIconButton
                    id={index}
                    onClick={() =>
                      setCategories(
                        categories.map((category: any, idx: any) =>
                          index === idx
                            ? { ...category, is_deleted: true }
                            : category
                        )
                      )
                    }
                  />
                  <StyledButton
                    data-te-collapse-init
                    aria-expanded={openModalIndex === index ? "true" : "false"}
                    isDisabled={!item?.menuId}
                    onClick={() => handleToggle(index)}
                  >
                    {openModalIndex === index ? "Close Menu" : "View Menu"}
                  </StyledButton>
                </div>

                <div
                  id={`sub_menu${index}`}
                  className={`ba_dd absolute top-full px-2.5 left-0 min-w-[240px] w-full z-20 ${
                    openModalIndex === index ? "block" : "hidden"
                  }`}
                  aria-labelledby={`sub_menu${index}`}
                >
                  <div className="bg-white rounded shadow-lt p-5">
                    {item?.sub_menu &&
                      item?.sub_menu.map(
                        (subCat: any, subIdx: any) =>
                          !subCat?.is_deleted && (
                            <div
                              className="w-full gap-4 mb-5 flex relative"
                              key={subIdx}
                            >
                              <AlgoliaAll
                                index={`sub-${subIdx}`}
                                inputValue={subCat?.name}
                                value={subCat}
                                handleHitSelect={handleNestHitSelect(
                                  setCategories,
                                  index,
                                  subIdx
                                )}
                                type={
                                  INITIAL_TYPES[subCat?.type] ?? subCat?.type
                                }
                                width={"full"}
                              />
                              <DeleteIconButton
                                id={subIdx}
                                onClick={removeNestedCategory(
                                  index,
                                  subIdx,
                                  setCategories
                                )}
                              />
                            </div>
                          )
                      )}

                    <StyledButton
                      onClick={addNestedCategory(setCategories, index)}
                    >
                      Add menu
                    </StyledButton>
                  </div>
                </div>
              </div>
            )
        )}
        <div className="w-full mb-5 px-2.5 relative">
          <button
            type="button"
            className={` group inline-flex group items-center transition font-medium rounded text-sm13 px-2.5 py-0.5 text-white hover:text-white bg-violet-500 hover:bg-indigo-500 `}
            onClick={() =>
              setCategories((prev: any) =>
                prev.concat({
                  id: "",
                  name: "",
                  type: "",
                  type_id: "",
                  sub_menu: [],
                })
              )
            }
          >
            <div
              className={`flex items-center justify-center  transition mr-2 fill-white `}
            >
              <AddIcon className="w-3.5 h-3" />
            </div>
            Add new subcategory
          </button>
        </div>
      </div>
    </div>
  );
};
