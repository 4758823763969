import { CrossIcon } from "assets/media/icons/other_icons/CrossIcon";
import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { useFormik } from "formik";
import { showAlert } from "helpers/ShowAlert";
import { size } from "lodash";
import { useEffect } from "react";
import { useMutation } from "react-query";
import ReactQuill from "react-quill";
import styled from "styled-components";
import * as Yup from "yup";
import AlgoliaAll from "../component/UI/AlgoliaAll";
import FileUpload from "../component/UI/FileUpload";
import { ContentAddRecord, getContentListings } from "../core/_.request";
import { useCustomize } from "../hooks/useCustomize";

const StyledEditor = styled(ReactQuill)`
  .ql-editor {
    min-height: 18em;
  }
`;
const Quill = StyledEditor.Quill;
var Font = Quill.import("formats/font");
Font.whitelist = [
  "Roboto",
  "sans-serif",
  "Raleway",
  "Montserrat",
  "Lato",
  "Rubik",
  "PT-Sans",
];
Quill.register(Font, true);

const editorToolbarOptions = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: Font.whitelist }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ color: [] }, { background: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
    ["code-block"],
  ],
};

const initialValues = {
  id: null,
  type: "",
  description: "",
  logo_image: [],
  banner_image: [],
};

const addEditRecordSchema = Yup.object().shape({
  type: Yup.mixed().required("Title is required"),
  description: Yup.string().required("Content is required"),
  logo_image: Yup.array().min(1, "Document is required"),
  banner_image: Yup.array().min(1, "Document is required"),
});

const handleSubmit = (mutation: any) => (data: any) => {
  mutation.mutate(data);
};

const AddNewRecordForContent = () => {
  const {
    contentFilter,
    isTabActive,
    isPopupActive,
    isLoading,
    setIsPopupActive,
    setIsLoading,
    setContentList,
  } = useCustomize();

  let isRecordEdit =
    isPopupActive?.status && isPopupActive?.data ? true : false;

  const mutation = useMutation({
    mutationFn: (data) => ContentAddRecord(data),
    onSuccess: async (response: any) => {
      if (response?.data && size(response?.data) > 0) {
        setIsLoading(false);
        if (response?.data?.status) {
          showAlert(response?.data?.message, false);
          getContentListings(contentFilter).then((data: any) => {
            if (
              data?.data?.status &&
              data?.data?.response &&
              size(data?.data?.response) > 0
            ) {
              setContentList(data?.data?.response);
              handleResetForm();
            }
          });
        }
      } else {
        setIsLoading(false);
        showAlert(
          response?.errors?.title
            ? response?.errors?.title
            : Array.isArray(response?.errors)
            ? response?.errors?.join("<br/>")
            : "Something went wrong!",
          true
        );
      }
    },
  });

  // FORMIK
  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addEditRecordSchema,
    validateOnMount: true,
    onSubmit: handleSubmit(mutation),
  });

  useEffect(() => {
    if (isRecordEdit) {
      const {
        type,
        title,
        type_id,
        id,
        description,
        logo_image,
        banner_image,
      } = isPopupActive.data;

      formik.setValues({
        id: id,
        type: {
          type,
          name: title,
          id: type_id,
        },
        description,
        logo_image: [logo_image],
        banner_image: [banner_image],
      });
    } else {
      // Reset to initial values if not editing
      formik.resetForm();
    }
  }, [isPopupActive.data, isRecordEdit]); // Run effect when isPopupActive.data or isRecordEdit changes

  // RESET FORM
  const handleResetForm = () => {
    formik.resetForm();
    setIsPopupActive({
      tabActive: isTabActive,
      status: false,
      data: undefined,
    });
  };

  const handleHitSelect = (data: any) => {
    let type = {
      type: data?.componentType?.name,
      id: data?.id,
      name: data?.name,
    };
    formik.setFieldValue("type", type);
  };

  return (
    <div
      data-te-modal-init
      className={`${
        isPopupActive?.status ? "show" : ""
      } offcanvas add_teammember fill_canvas offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium  w-full upload-ticket-popup z-40`}
      tabIndex={-1}
      id="add_new_content"
      aria-labelledby="AddContentLabel"
    >
      {/* FORMIk */}
      <form className={`h-full md:max-w-[43.125rem] ml-auto max-w-full `}>
        <div className="  bg-white shadow-3xl w-full h-full ml-auto relative flex flex-col">
          <div className="offcanvas-header flex justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
              id="offcanvasRightLabel"
            >
              {isRecordEdit ? "Edit" : "Add new"} record
            </h5>
            <div className="buttons flex flex-wrap">
              <button
                type="button"
                className="flex items-center justify-center w-9 border-l box-content group"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={handleResetForm}
              >
                <div className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition">
                  <CrossIcon className="w-2.5 h-2.5" />
                </div>
              </button>
            </div>
          </div>

          {/* MAIN FORM */}
          <div className="offcanvas-body h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full px-5 py-8 mb-1">
            <div className={""}>
              <div className={""}>
                <div className="address_fields flex flex-wrap ">
                  <div className="fieldset w-full flex flex-wrap">
                    {/* Name */}
                    <div className="w-full  mb-5 relative">
                      <AlgoliaAll
                        width="w-full !px-0"
                        index={1}
                        type={formik.values?.type?.type}
                        handleHitSelect={handleHitSelect}
                        inputValue={formik.values?.type?.name}
                        handlerResetValue={() =>
                          formik.setFieldValue("type", null)
                        }
                      />
                    </div>
                  </div>

                  {/* DESCRIPTION */}
                  <div className="fieldset w-full flex flex-wrap">
                    <div className="w-full relative mb-5">
                      <StyledEditor
                        theme="snow"
                        value={formik.values?.description}
                        onChange={(value: any) =>
                          formik.setFieldValue(
                            "description",
                            value.trim() === "<p><br></p>" ? "" : value
                          )
                        }
                        modules={editorToolbarOptions}
                        placeholder="Write content...."
                      />
                      {formik.touched.description &&
                        formik.errors.description && (
                          <div className="text-xs text-rose-500 error-msg">
                            {formik.errors.description as string}
                          </div>
                        )}
                    </div>
                  </div>

                  {/* LOGO */}
                  <div className="w-full flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0">
                    <div className="title_block w-full border-b  flex  justify-between min-h-[2.5rem]">
                      <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center justify-between flex-1 font-semibold">
                        Logo
                      </h5>
                    </div>
                    <FileUpload
                      files={formik?.values?.logo_image}
                      onFilesUploaded={(data: any) => {
                        formik.setFieldValue("logo_image", data);
                      }}
                      deleteFiles={() => formik.setFieldValue("logo_image", [])}
                    />
                  </div>
                  <div className="w-full flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0">
                    <div className="title_block w-full border-b  flex  justify-between min-h-[2.5rem]">
                      <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center justify-between flex-1 font-semibold">
                        Banner
                      </h5>
                    </div>
                    <FileUpload
                      files={formik?.values?.banner_image}
                      onFilesUploaded={(data: any) => {
                        formik.setFieldValue("banner_image", data);
                      }}
                      deleteFiles={() =>
                        formik.setFieldValue("banner_image", [])
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* BUTTONS */}
          <div className=" offcanvas-footer bottom-6 right-0 w-full flex justify-start px-5 py-3 bg-white z-10 border-t">
            <div className="l_btn gap-2.5 flex flex-wrap items-center">
              <button
                type="button"
                className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                data-bs-dismiss="offcanvas"
                onClick={handleResetForm}
              >
                Cancel
              </button>
              <button
                type="button"
                // disabled={allFieldsEmpty}
                onClick={(e: any) => {
                  e.preventDefault();
                  setIsLoading(true);
                  formik.submitForm();
                }}
                className={`relative z-10 border border-green-600 active:text-white text-white hover:bg-indigo-500 hover:border-indigo-500 bg-green-600 active:bg-green-600 transition font-semibold rounded text-sm13 px-2.5 py-0.5 ${
                  false && "pointer-events-none !bg-gray-300 !border-gray-300"
                }`}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
      {isLoading && <ProgressBardLoader secondCounter={1.5} />}
    </div>
  );
};

export default AddNewRecordForContent;
