import { Tab } from "components/ui/Tab/Tab";
import { CUSTOMIZE_TABS } from "pages/customize/core/constants/constants";
import { useCustomize } from "pages/customize/hooks/useCustomize";

const Tabs = () => {
  const { isTabActive, setTabActive } = useCustomize();

  return (
    <div
      id="inventoryaccordion"
      data-te-collapse-item
      data-te-collapse-show
      aria-labelledby="headingOne"
    >
      <div className="tab-panel bg-no-repeat bg-trade pattern bg-cover bg-indigo-500 bg-opacity-[15%] p-5 pb-0 flex after:content after:absolute after:bottom-0 after:left-0 after:w-full after:h-2 after:z-0 after:from-black/0 after:bg-gradient-to-b after:to-black/5 relative overflow-x-auto flex-nowrap overflow-hidden scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
        {CUSTOMIZE_TABS.map(({ key, title }) => (
          <Tab
            className="lg2:max-w-[14.9375rem] min-w-[10.625rem lg:whitespace-normal"
            key={key}
            title={title}
            isActiveTab={isTabActive === key}
            tabKey={key}
            handleTab={(key: any) => {
              if (isTabActive !== key) {
                setTabActive(key);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Tabs;
