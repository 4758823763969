export const mapEditRecordDataToFormData = (params: any) => {
  const { data, categories } = params;

  const createCategory = (item: any, parentId: any) => ({
    type: item?.componentType?.name || item?.type,
    ...(item?.menuId && { id: item.menuId }),
    ...(item?.is_deleted && { is_deleted: item.is_deleted }),
    type_id: item.id,
    parent_id: parentId,
    title: item.name,
    status: data?.status,
    position: data?.position,
  });

  const modifiedCategories = categories
    .filter(
      (item: any) =>
        (item.id && item.componentType?.name) || (item?.name && item?.type)
    )
    .map((item: any) => createCategory(item, data?.id));

  const subModifiedCategories = categories.flatMap(
    (item: any) =>
      item.sub_menu
        ?.filter((submenu: any) =>
          Boolean(
            (submenu.id && submenu.componentType?.name) ||
              (submenu?.name && submenu?.type)
          )
        )
        .map((submenu: any) => createCategory(submenu, item.menuId)) || []
  );

  return {
    is_main_item: false,
    items: [
      { ...data, parent_id: null },
      ...modifiedCategories,
      ...subModifiedCategories,
    ],
  };
};

export const handleScrollTable = () => {
  const EventAccordion = document.getElementById(
    "EventAccordion"
  ) as HTMLElement;

  const topfilterTop: any = document.getElementById("topFilters");

  if (topfilterTop) {
    const topValue: any = topfilterTop?.style?.top; // Retrieve the current 'top' value
    // if (topValue.includes("-")) {
    // Check if the value contains a negative sign
    const currentValue = parseInt(topValue); // Extract the numeric value
    const newValue = Math.abs(currentValue); // Make it positive
    topfilterTop && (topfilterTop.style.top = `${newValue}px`); // Apply the new positive value back as a string
    // }
    const windScroll: number = window.scrollY;

    const headingOne: any =
      document.getElementById("headingOne")?.clientHeight;
    const inventoryaccordion: any =
      document.getElementById("inventoryaccordion")?.clientHeight;
    // const totleScroll = headingOne + inventoryaccordion + 16;
    // const currentTop = parseInt(topfilterTop.style.top || "0");
    const topFiltersHeight = topfilterTop.clientHeight;
    let totleScroll = 0;
    if (window.matchMedia("(max-width: 767px)").matches) {
      totleScroll = topFiltersHeight;
    } else {
      totleScroll = headingOne + inventoryaccordion + 16;
    }

    const stickyHead: any = document.getElementById("sticky-head");
    const stickyShadow: any = document.getElementById("sticky-shadow");
    if (windScroll > totleScroll) {
      EventAccordion?.classList?.add("-mt-10");
      stickyHead?.classList.remove("opacity-0");
      stickyHead?.classList.remove("h-0");
      stickyHead?.classList.add("h-[2.5625rem]");
      stickyShadow.classList.add("!block");
    } else if (windScroll < totleScroll) {
      EventAccordion?.classList?.remove("-mt-10");
      stickyHead?.classList.add("opacity-0");
      stickyHead?.classList.add("h-0");
      stickyHead?.classList.remove("h-[2.5625rem]");
      stickyShadow.classList.remove("!block");
    }
  }
};