import { QUERIES } from "helpers/const";
import { size } from "lodash";
import useDebounce from "pages/add_inventory/core/useDebounce";
import { algoliaIndexes } from "pages/customize/core/_.model";
import { algoliaSearch } from "pages/customize/core/_.request";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import MultiSelectWithButtons from "./MultiSelectWithButtons";

const AlgoliaAll = ({
  value,
  index,
  handleHitSelect,
  width = "",
  inputValue = "",
  type = "",
  handlerResetValue = () => {},
  isValuesNeeded = true,
}: any) => {
  const [inputChange, setInputChange] = useState<any>("");
  const [typeComponent, setTypeComponent] = useState({ name: "Event" });
  const [algoliaOptions, setAlgoliaOptions] = useState<any>();

  const algoliaDebounce = useDebounce(inputChange, 1200);

  const { isFetching } = useQuery(
    [`${QUERIES.CUSTOMIZE_ALGOLIA}`, algoliaDebounce],
    () => {
      if (inputChange && inputChange.trim() !== "") {
        return algoliaSearch({ inputChange });
      }
      return Promise.resolve();
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: true,
      enabled: algoliaDebounce ? true : false,
      onError: (err) => {},
      onSettled(data, error) {
        if (data && size(data) > 0) {
          const newDataState = [
            { hits: data?.[0]?.hits || [], index: "Event" },
            { hits: data?.[1]?.hits || [], index: "Performer" },
            { hits: data?.[2]?.hits || [], index: "Venue" },
            { hits: data?.[3]?.hits || [], index: "Category" },
          ];
          setAlgoliaOptions(newDataState);
        }
      },
    }
  );
  const useAlgoliaOptions = (algoliaOptions: any[], typeComponent: any) => {
    return useMemo(() => {
      // Ensure algoliaOptions and typeComponent are defined
      if (!algoliaOptions || !typeComponent || !typeComponent.name) {
        return []; // Return an empty array if not properly defined
      }

      // Find the matched object from algoliaOptions based on the index
      const matchedOption = algoliaOptions.find(
        (item: any) => item?.index === typeComponent.name
      );

      // Return the hits from the matchedOption if found, or an empty array
      return matchedOption ? matchedOption.hits : [];
    }, [algoliaOptions, typeComponent]);
  };

  const handleInputChange =
    (algoliaDebounce: any, setInputChange: any, setAlgoliaOptions: any) =>
    (data: any, e: any) => {
      if (["menu-close", "set-value", "input-blur"].includes(e.action)) {
        if (!data) {
          setInputChange((current: any) => {
            return current;
          });
        }
      } else {
        setInputChange(() => {
          if (algoliaDebounce !== data) {
            setAlgoliaOptions([]);
          }
          return data;
        });
      }
    };

  const handleAlgoliaButtons = (data: any) => {
    let isCrossBtn =
      data.target?.id === "Path_382" ||
      data.target?.id === "Path_26" ||
      data.target?.id === "cross";
    if (isCrossBtn) {
      setInputChange(undefined);
      setAlgoliaOptions([]);
      handlerResetValue();
    } else {
      setTypeComponent(data);
    }
  };

  useEffect(() => {
    if (inputValue) setInputChange(inputValue);
    else setInputChange("");
  }, [inputValue]);

  useEffect(() => {
    if (type)
      setTypeComponent({
        name: algoliaIndexes?.includes(type) ? type : "Event",
      });
  }, [type]);

  return (
    <div
      className={`w-full ${
        width || "lg:w-1/2 lg2:w-1/3  xl:w-[40%]"
      } lg:px-[.3125rem]`}
    >
      <div
        className={`relative rounded flex-1 max-w-full md:mb-0 ${
          false && "shimmer-effect"
        }`}
      >
        <div className="w-full">
          <MultiSelectWithButtons
            name={`type_components_${index}`}
            options={useAlgoliaOptions(algoliaOptions, typeComponent)}
            isLoading={isFetching}
            handleOnChange={(e: any) => {
              let value = e.target.value;

              if (value?.name && isValuesNeeded)
                setInputChange(value?.name ?? "");
              handleHitSelect({
                ...value,
                componentType: {
                  ...typeComponent,
                  id: `type_components`,
                  index: index,
                },
              });
            }}
            value={value || null}
            inputValue={inputChange}
            handleInputChange={handleInputChange(
              algoliaDebounce,
              setInputChange,
              setAlgoliaOptions
            )}
            handleKeyDown={() => {}}
            onSearch={(data: any) => {
              handleAlgoliaButtons(data);
            }}
            isMulti={false}
            classNamePrefix="inventory_select"
            className="searchbar-wrap"
            placeholder={`Search ${typeComponent?.name}`}
            menuPortalTarget={document.body}
            typeComponent={typeComponent}
          />
        </div>
      </div>
    </div>
  );
};

export default AlgoliaAll;
