export const addNestedCategory = (setCategories: any, index: any) => () => {
  setCategories((prev: any) =>
    prev.map((prevItem: any, i: any) => ({
      ...prevItem,
      sub_menu:
        i === index
          ? prevItem.sub_menu.concat({
              id: "",
              name: "",
              type: "",
              type_id: "",
            })
          : prevItem.sub_menu,
    }))
  );
};

export const removeNestedCategory =
  (mainIndex: any, subIndex: any, setCategories: any) => () => {
    setCategories((prevstate: any) =>
      prevstate.map((prevItem: any, index: any) => ({
        ...prevItem,
        sub_menu:
          index === mainIndex
            ? prevItem.sub_menu.map((subItem: any, subIdx: any) =>
                subIdx === subIndex
                  ? {
                      ...subItem,
                      is_deleted: true,
                    }
                  : subItem
              )
            : prevItem.sub_menu,
      }))
    );
  };

export const handleHitSelect =
  (setCategories: any, index: any) => (data: any) => {
    setCategories((prev: any) => {
      return prev.map((item: any, Iidx: any) =>
        Iidx === index ? { ...item, ...data } : item
      );
    });
  };

export const handleNestHitSelect =
  (setCategories: any, index: any, subIdx: any) => (data: any) =>
    setCategories((prev: any) =>
      prev.map((prevItem: any, i: any) => ({
        ...prevItem,
        sub_menu:
          i === index
            ? prevItem.sub_menu.map((sub: any, subI: any) =>
                subI === subIdx
                  ? {
                      ...sub,
                      ...data,
                    }
                  : sub
              )
            : prevItem.sub_menu,
      }))
    );
