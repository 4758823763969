import { QUERIES } from "helpers/const";
import { size } from "lodash";
import { getContentListings } from "pages/customize/core/_.request";
import { useCustomize } from "pages/customize/hooks/useCustomize";
import { useQuery } from "react-query";
import DataTable from "../DataTable";

const TableWrapper = () => {
  const { contentFilter, setContentList } = useCustomize();

  const { isLoading, refetch, data } = useQuery(
    [`${QUERIES.CONTENT_LIST}`, contentFilter],
    () => getContentListings(contentFilter),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onSettled(data, error) {
        if (
          data?.data?.status &&
          data?.data?.response &&
          size(data?.data?.response) > 0
        ) {
          setContentList(data?.data?.response);
        }
      },
    }
  );

  return (
    <>
      <DataTable
        paginateData={data?.meta}
        loading={isLoading}
        refetch={refetch}
      />
    </>
  );
};

export default TableWrapper;
